import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "twin.macro";

import SEO from "./seo";
import Header from "./header";
import Footer from "./footer";

const Layout = ({
  children,
  pageContext,
  title,
  description,
  menu,
  lang,
  schema,
}) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `); // FIXME graphql query shouldn't handle only one language - maybe move this to props?

  return (
    <>
      <SEO
        title={title}
        description={description}
        lang={lang}
        schema={schema}
        pageContext={pageContext}
      />
      <Header
        siteTitle={data.site.siteMetadata.title}
        menu={menu}
        lang={lang}
      />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  menu: Header.propTypes.menu,
};

const Main = styled.main`
  ${tw`flex-1`}

  & section:last-child {
    padding-bottom: 0;
  }
`;

export default Layout;
