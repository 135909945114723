// TODO add links to TopBar if screen width >= md

import { React, useState, useCallback } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import tw from "twin.macro";

import logo from "../pages/images/logo.png";

const Header = ({ siteTitle, menu, lang }) => {
  const {
    site: {
      siteMetadata: { defaultLang, phone, email },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          phone
          email
          defaultLang
        }
      }
    }
  `);
  const contactInfo = { phone, email };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [contactInfoHeight, setContactInfoHeight] = useState(0);
  const contactInfoRef = useCallback((node) => {
    if (!node) return;
    setContactInfoHeight(node.getBoundingClientRect().height);
  }, []);

  const handleHamburgerClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <HtmlHeader contactInfoHeight={contactInfoHeight}>
      <ContactInfoBar ref={contactInfoRef}>
        <ContactInfo>
          {contactInfo.phone}
          <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
        </ContactInfo>
      </ContactInfoBar>
      <Navigation>
        <TopBar isMenuOpen={isMenuOpen}>
          <HomeLink to={lang === defaultLang ? `/` : `/${lang}/`}>
            <Logo src={logo} alt={siteTitle} />
          </HomeLink>
          {menu.length > 0 ? (
            <MenuHamburger onClick={handleHamburgerClick} />
          ) : null}
        </TopBar>
        {menu.length > 0 ? (
          <Menu isOpen={isMenuOpen}>
            {menu.map(({ id, to, title }) => (
              <MenuItem key={id}>
                <MenuLink to={to}>{title}</MenuLink>
              </MenuItem>
            ))}
          </Menu>
        ) : null}
      </Navigation>
    </HtmlHeader>
  );
};
Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  menu: PropTypes.arrayOf(
    PropTypes.exact({
      to: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
};
Header.defaultProps = {
  siteTitle: ``,
  menu: [],
};

const HtmlHeader = styled.header`
  ${tw`sticky z-50 flex flex-col items-center w-full bg-white`}
  top: -${({ contactInfoHeight }) => contactInfoHeight}px;
`;
const ContactInfoBar = styled.div`
  ${tw`flex justify-center w-full border-b border-neutral-light-500`}
`;
const ContactInfo = styled.div`
  ${tw`flex justify-around w-full max-w-md`}
`;
const Navigation = styled.div`
  ${tw`w-full`}
`;
const TopBar = styled.div`
  ${tw`relative z-10 flex items-center justify-between w-full h-12 p-2 shadow-lg`}
  ${({ isMenuOpen }) => (isMenuOpen ? tw`shadow-md` : tw`shadow-lg`)}
`;
const HomeLink = styled(Link)`
  ${tw`h-full`}
`;
const Logo = styled.img`
  ${tw`w-auto h-full`}
`;

const Menu = ({ children, isOpen }) => (
  <MenuWrapper isOpen={isOpen}>
    <MenuList>{children}</MenuList>
  </MenuWrapper>
);
const MenuWrapper = styled.nav`
  ${tw`absolute w-full py-4 bg-white shadow-lg`}
  ${({ children }) => (children ? "" : tw`hidden`)}
  ${({ isOpen }) => (isOpen ? "" : tw`hidden`)}
  top: 100%;
`;
const MenuList = styled.ul`
  ${tw`flex flex-col w-full list-none`}
`;

const MenuItem = styled.li`
  ${tw`w-full`}
`;
const MenuLink = styled(Link)`
  ${tw`inline-block w-full py-2 text-center uppercase bg-white hover:bg-neutral-light-300 focus:bg-neutral-light-300`}
`;

const MenuHamburger = ({ onClick }) => (
  <HamburgerButton onClick={onClick}>
    <HamburgerIcon>
      <HamburgerBar top={`25%`} width={`100%`} />
      <HamburgerBar top={`50%`} width={`75%`} />
      <HamburgerBar top={`75%`} width={`50%`} />
    </HamburgerIcon>
  </HamburgerButton>
);
const HamburgerButton = styled.button`
  ${tw`p-2 bg-transparent rounded appearance-none`}
`;
const HamburgerIcon = styled.div`
  ${tw`relative`}
  height: 32px;
  width: 32px;
`;
const HamburgerBar = styled.span`
  ${tw`absolute inline-block bg-black`}
  ${({ top, width }) => `
      top: ${top};
      right: 0;
      height: 3px;
      width: ${width};
    `}
`;

export default Header;
