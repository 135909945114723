import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import favicon from "../pages/images/favicon.png";

export default function({ pageContext, title, description, schema }) {
  let { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
          }
        }
      }
    `
  ); // FIXME graphql query shouldn't handle only one language - maybe move this to props?
  site = site.siteMetadata;
  description = description || "";
  title = title || "";
  const page = { url: site.siteUrl + pageContext.slug, ...pageContext };

  return (
    <Helmet
      htmlAttributes={{
        lang: pageContext.lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.title}`}
      defaultTitle={site.title}
    >
      <html lang={pageContext.lang} />
      <meta name="decription" content={description}></meta>
      <meta name="og:title" content={title}></meta>
      <meta name="og:decription" content={description}></meta>
      <meta name="og:url" content={page.url}></meta>
      <meta name="og:type" content="website"></meta>
      <meta name="twitter:card" content={description}></meta>
      <meta name="twitter:creator" content={site.author}></meta>
      <meta name="twitter:title" content={title}></meta>
      <meta name="twitter:description" content={description}></meta>
      <script type="application/ld+json">{schema}</script>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto+Condensed|Saira+Condensed:500,600&display=swap"
      />
      <link rel="shortcut icon" href={favicon} />
      <link rel="canonical" href="page.url" />
    </Helmet>
  );
}
