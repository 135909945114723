import { useStaticQuery, graphql } from "gatsby";
import { React } from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

const Footer = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <HtmlFooter>
      © {new Date().getFullYear()} {title}
    </HtmlFooter>
  );
};

const HtmlFooter = styled.footer`
  ${tw`flex justify-center`}
`;

export default Footer;
